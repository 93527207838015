* {
  margin: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;
  -webkit-tap-highlight-color: transparent;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

.disable-scrolling {
  height: 100%;
  overflow: hidden;
}

footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}

.page-container {
  width: 90%;
  max-width: 1360px;
  margin: auto;
}

.page-container-small {
  width: 95%;
  max-width: 1220px;
  margin: auto;
}

.page-container-small-left {
  margin-left: 2.5%;
}

.page-cushion-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
}

.page-cushion-container--left {
  width: 100%;
  padding-left: 16px;
}

@media only screen and (min-width: 1024px) {
  .page-cushion-container {
    padding-left: 60px;
    padding-right: 60px;
  }

  .page-cushion-container--left {
    width: 100%;
    padding-left: 60px;
  }
}

.text-highlighter-underline {
  margin: auto;
  width: 46px;
  height: 4px;
  background: #979797;
  border-radius: 2px;
}

/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-arrow {
  display: none !important;
}

.MuiDrawer-paperAnchorTop {
  margin-top: 124px;
}

input {
  outline: none;
  font-family: 'Manrope', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  font-family: 'Manrope', sans-serif;
}

.swiper-slide {
  width: auto !important;
}

div {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#hubspot-messages-iframe-container.widget-align-right {
  bottom: 56px !important;
  max-height: 93% !important;
}
